export function getFacStatus(status: string) {
  switch (status) {
    case 'NEW_CONTACT':
      return 'Novo Contato';
    case 'UNABLE_TO_CONTACT':
      return 'Contato não estabelecido';
    case 'TWO_ATTEMPT':
      return 'Segunda Tentativa';
    case 'VISIT_SCHEDULED':
      return 'Visita Agendada';
    case 'CONTACT_TERMINATED':
      return 'Contato Feito';
    case 'NOT_INTERESTED':
      return 'Sem Interesse';
    case 'LEAD_DISQUALIFIED':
      return 'Lead Desqualificada';
    default:
      return 'Novo Contato';
  }
}

export function getFacInterest(productInterest: string){
  switch (productInterest){
    case 'CORN':
    return 'Milho'
    case 'SOY':
    return 'Soja'
    case 'SORGHUM':
    return 'Sorgo'
    default:
      return ''
  }
}
export function getFacDealType(dealType: string){
  switch (dealType){
    case 'BUY':
    return 'Comprar'
    case 'SELL':
    return 'Vender'
    case 'BUY_SELL':
    return 'Comprar e Vender'
    case 'STORE':
    return 'Armazenar'
    default:
      return ''
  }
}

export function getFacOrigin(origin: string) {
  switch (origin){
    case 'MOBILE':
    return 'Aplicativo'
    case 'SITE_BID':
    return 'Página de Oferta do Site'
    case 'SITE_HOME':
    return 'Página Inicial do Site'
    case 'SITE_PARTNERS':
    return 'Página de Parceiros do Site'
    case 'SITE_CONTACT':
    return 'Página de Contato do Site'
    case 'SITE':
    return 'Site'
    default:
      return 'Site'
  }
}